@import 'src/styles.scss';

$font-size: 16px;
$font-color: #005825;
$font-weight: inherit;
$line-height: inherit;
$letter-spacing: inherit;

@font-face{
    font-family: Myriad-Pro Light;
    src: url('/assets/templates/template_009/fonts/MyriadPro-Light.otf');
}

@font-face{
    font-family: Myriad-Pro Regular;
    src: url('/assets/templates/template_009/fonts/MyriadPro-Regular.otf');
}

@font-face{
    font-family: Myriad-Pro Bold;
    src: url('/assets/templates/template_009/fonts/MyriadPro-Bold.otf');
}

@font-face {
    font-family: ZapfinoForteLTPro;
    src: url('/assets/templates/template_009/fonts/ZapfinoForteLTPro.otf');
}

#suggestion-container {
    @include container(100%, 100%);
    border-bottom: medium none !important;
}
.search-panel i{
    display: none;
}
input:focus {
    outline: medium none !important;
}
app-partners{
    height: 100%;
    width: 100%;
    display: inline-block;
    padding: 15px;
}
::placeholder{
    color:#7A7A7A !important;
    font-size: 12px;
}
#suggestion-container input{
    font-size:12px;
    width: calc(100% - 10px);
    height:100%;
    border-radius: 15px;
    margin:0px !important;
    color:#7A7A7A;
    padding-left: 10px;
    ::-moz-placeholder{
        color:red;
        opacity: 1;
    }
}
.goPnl {
    width: 30px;
}
#lnkGo {
    line-height: 1.8;
    text-align: right;
    font-family: fontawesome !important;
}
.blog-content-panel .content img {
    display: none ;
}
app-search{
    .searchBar{
        background-color: #d6d6d8;
        border-radius: 15px;
        height: 90%;
    }
}
button:focus, a:focus {
    outline: medium none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
perfect-scrollbar{
    margin-bottom: 20px !important;
    max-height: 240px !important;
    overflow: auto !important;
    height: auto !important;
}
.typeSpecFilterPanel perfect-scrollbar{
    overflow: auto !important;
    max-height: 145px !important;
}
#options-overlay {
    width: 14% !important;
    max-height: 220px !important;
}
#options-overlay a {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
    display: inline-block;
    width: 100%;
}
.waves-effect.option div {
    padding: 7px;
}
.loginPopUp .login-component{
    height: 420px;
    width: 500px;
    max-width: 500px !important;
    margin-bottom: 0 !important;
}
.loginPopUp .heading-lbl{
    visibility: visible !important;
}
body{
    font-family: Myriad-Pro Regular;
    min-height: 100%;
    height: auto !important;
}
.mat-dialog-container{
    border: medium none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 10px !important;
}
.hide{
    display: none !important;
    opacity: 0;
}
.rating-container{
    padding: 0px !important;
}
ls-suggestion{
    width: 90% !important;
    height:100% !important;
}
ls-suggestion i{
    display: none !important;
}
a {
    text-decoration: none;
    outline: medium none;
}
input.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
.master{
    height: auto !important;
    position: relative;
}
.file-snip p{
    font-family: Myriad-Pro Regular;
    color: #005825;
    font-size: 14px;
}
.ps__rail-y {
    background-color: #aaa !important;
    width: 3px !important;
}
.ps__thumb-y {
    width: 4px !important;
    right: 0px !important;
    background-color: rgb(110, 171, 30) !important;
   
}
.static-page *{

}
select:focus{
    outline: none;
}
.defaultWidth{
    width: 1000px;
    margin: 0 auto;
}
@media screen and (min-width:1024px){
    .panelClass {
        position: absolute !important;
        right: 22% !important;
        top: 130px;
    }
}
@media screen and (max-width: 1024px){
    .defaultWidth{
        width: 100%;
        margin: 0;
    }
    ls-suggestion{
        width: 95% !important;
    }
    .search-panel app-search > div{
        background-color: transparent;
    }
    app-search{
        width: 100%;
    }
    #options-overlay{
        width: 80% !important;
    }
    .menu-width {
        width: 70% !important;
        height: 100vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass {
        position: absolute !important;
        right: 0% !important;
        top: 130px;
    }
    .static-page *{
       max-width: 100%;
       display: inline-block;
    }
    .showBg{
        background-image: url('/assets/templates/template_009/images/background-image-mobile.jpg')
    }
}

@media screen and (max-width:767px){
    #options-overlay {
        width: 75% !important;
    }
    .static-page * {
        max-width: 100%;
        // width: 100%;
        line-height: 1.2;
        display: inline-block;
        font-size: 1em !important;
    }
    .static-page img {
        width: 100% !important;
        height: auto;
    }
    #suggestion-container input,span#lnkGo{
        color: #a0a0a0 !important;
    }
    .cdk-global-overlay-wrapper{
        background-color: rgba(0,0,0,0.7) !important;
    }
    app-order-tracking {
        padding: 0 20px;
        display: inline-block;
        width: 100%;
    }
    .mce-panel {
        width: 100% !important;
    }
    ._2p3a{
        min-width: 100% !important;
    }
    .static-page p{
        line-height: 1.5;
    }
    .typeSpecFilterPanel perfect-scrollbar{
        margin-bottom: 0 !important
    }
    .menu-width {
        width: 80% !important;
        height: 78vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass{
        position: static !important;
    }
}
@media screen and (min-width :1024px) and (max-width:1280px){
    .panelClass{
        right: 0 !important;
    }
}